@supports (display:-ms-grid) {
    .App {
        width: 100vw;
        height: auto;
        display: -ms-grid;
        -ms-grid-rows: 30px 1fr;
    }
}

@supports (display:grid) {
    .App {
        width: 100vw;
        height: auto;
        display: grid;
        grid-template-rows: 30px auto;
    }
}

@supports (display:-ms-grid) {
    .content {
        display: block;
        overflow: hidden;
        -ms-grid-row-span: 2;
    }
}

@supports (display:grid) {
    .content {
        display: block;
        overflow: hidden;
        grid-area: 1 / 1 / 3 / 1;
    }
}

.search {
    overflow: auto;
}

@supports (display:-ms-grid) {
    .bottomBox {
        -ms-grid-row: 2;
    }
}

@supports (display:grid) {
    .bottomBox {
        grid-area: 1 / 1 / 3 / 1;
        padding-top: 3px;
    }

}

html, body {
    margin: 0;
    padding: 0;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.menu-button {
    /* width: 50%; */
    margin-right:5px;
}

.menu-button-right {
    float: right;
}
