.cleanTable {
    border-collapse: collapse;
    width: 100%;
    height: 100%;
    display: block;
    overflow-x: auto;
    overflow-y: auto;
}

.cleanTable > thead > tr > th, .cleanTable > tbody > tr > td, .cleanTable > tbody > tr > th {
    border: 1px solid black
}

.StatisticsTable-antiButton {
    border: none;
    outline: none;
    background: none;
}

.StatisticsTable-antiButton:focus {
    outline: none;
}

.StatisticsTable-redLink {
    background:none!important;
    border:none;
    padding:0!important;

    /*optional*/
    font-family:arial,sans-serif; /*input has OS specific font-family*/
    color: #C33;
    cursor:pointer;

    text-align: left;
    vertical-align: text-top;
}

.StatisticsTable-redLink:hover{
    text-decoration:underline;
}