.box {
    display: grid;
    grid-template-rows: auto auto auto min-content;
    grid-template-columns: min-content auto auto auto;
}

.svg {
    grid-row: 1 / 4;
    grid-column: 2 / 5;
    width: 100%;
    height: 100%;
}

.selectCSS {
    font-size: 15px;
}

.selectYContainer {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    width: 20px;
    height: 200px;
}

.selectY {
    transform: rotate(90deg);
    transform-origin: 0 100%;
}

.selectX {
    grid-row: 4 / 5;
    grid-column: 3 / 4;
}

.legend {
    grid-row: 5 / 6;
    grid-column: 1 / 5
}

.moveSecondDown > g:nth-child(2n + 1) > text {
    transform: translateY(15px)
}
