/* slightly transparent fallback */
.backdropBlur {
    background-color: rgba(127, 127, 127, .9);
}

/* if backdrop support: very transparent and blurred */
@supports ((-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))) {
    .backdropBlur {
        background-color: rgba(255, 255, 255, .5);
        -webkit-backdrop-filter: blur(2em);
        backdrop-filter: blur(2em);
    }
}

.ChartTable {
    overflow: hidden;
    margin: 0 10px;
}