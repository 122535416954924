.offsetTwentyRight {
    padding-left: 20px;
}

.textCenter {
    text-align: center;
}

.textLeft {
    text-align: left;
}
