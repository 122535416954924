.wrapper {
    width: 100%;
    height: auto;
}

.header {
    width: 100%;
}

.header h2 {
    color: white;
    text-align: center;
    font-size: 4rem;
    font-weight: 400;
}

.header a {
    text-align: center;
}

.particlesJs {
    width: 100%;
    height: 340px;
    max-height: 400px;
    background-size: cover;
    background: #212000 no-repeat 50% 50%;
    /* padding-top: 4rem;
    padding-bottom: 2rem; */
    text-align: center;
}

.heroCaption {
    position: absolute;
    top: 25%;
    display: block;
    background: transparent;
    width: 100%;
    z-index: 100;
}

canvas {
    display: block;
    vertical-align: top;
}

.header canvas {
    position: relative;
}

.antiButton {
    border: none;
    outline: none;
    background: none;
}

.antiButton:focus {
    outline: none;
}

.offset-20 {
    padding-left: 20px;
}

.float-right-600 {
    float: right;
}

@media (max-width: 1100px) {
    .float-right-600 {
        float: left;
    }
}

.noMarginNoPadding {
    margin: 0;
    padding: 0;
}

.d-flex {
    display: flex;
}

/* grid */

@media screen and (min-width: 768px) {
    @supports (display: grid) {
        .grid4 {
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 20px;
        }
        .grid2 {
            grid-template-columns: 1fr 1fr;
            grid-gap: 20px;
        }
    }
    @supports (display: -ms-grid) {
        .grid4 {
            -ms-grid-columns: 1fr 1fr 1fr 1fr;
            grid-gap: 20px;
        }
        .grid2 {
            -ms-grid-columns: 1fr 1fr;
            grid-gap: 20px;
        }
    }
}

@media screen and (max-width: 768px) {
    @supports (display: -ms-grid) {
        .grid4 {
            -ms-grid-rows: 1fr 1fr 1fr 1fr;
            grid-gap: 20px;
        }
        .grid2 {
            -ms-grid-rows: 1fr 1fr;
            grid-gap: 20px;
        }
    }
    @supports (display: grid) {
        .grid4 {
            grid-template-rows: repeat(4, 1fr);
            grid-gap: 20px;
        }
        .grid2 {
            grid-template-rows: 1fr 1fr;
            grid-gap: 20px;
        }
    }
}

/* Offer */

.offer {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    background-color: white;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.title {
    letter-spacing: 1.5px;
    font-size: 1.75rem;
    font-weight: 400;
    margin-bottom: 24px;
}

.titleSmall {
    font-size: 1.5rem !important;
}

.titleSub {
    font-size: 1.1rem !important;
    color: var(--body-text-light);
}

.offerSvg {
    text-align: center;
    padding: 1rem 0;
}

.offerText {
    text-align: center;
    height: 150px;
    vertical-align: middle;
    font-weight: 500;
}

@media screen and (max-width: 768px) {
    .offer {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
    .offerText {
        height: 50px;
    }
}

/* exploration parameters */

.parameters {
    width: 100%;
}

.parametersBoxed {
    width: 90%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    padding: 2rem 1.653rem;
    min-height: 500px;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, 0.2);
}

.parametersValues {
    /* border: 1px solid rgba(0,0,0,.20); */
    background-color: #eff4f6;
}

.parametersBoxed label {
    width: 100%;
    padding: 10px 4px;
}

/* TODO: apply exploration to all non button inputs */
.exploration input:not([type="button"]):not([type="checkbox"]):not([type="submit"]):not([type="file"]),
.exploration select {
    width: 90%;
    background: hsl(192, 26%, 95%);
    padding: 7px 12px;
    border: 0px solid transparent;
    border-bottom: 1px solid rgba(29, 146, 35, .7);
    /* box-shadow: 3px 2px 4px 0 hsla(0, 0%, 0%, 0.2); */
}

.exploration input[type="file"] {
    background: transparent;
    border-bottom-color: transparent;
    margin-top: 10px;
}

.exploration input[type="file"]:hover {
    border-bottom-color: transparent;
}

.exploration input:hover,
.exploration input:focus,
.exploration select:hover,
.exploration select:focus,
.datepicker:hover {
    border-bottom: 1px solid #9d63c9;
}

.exploration select[name="selectIssue"] {
    border: 1px solid #d3d3d3;
    margin-top: 10px;
    background-color: white;
}

.boxedTitle {
    font-size: 1.0542rem;
}

.boxedTitleSub {
    font-size: 1.0532rem;
    /* color: #5f3382; */
}

.boxedTitleSub button {
    margin-left: 15px;
    padding: 2px 25px;
}

.datepicker {
    width: 90%;
    background: white;
    border-bottom: 1px solid rgba(29, 146, 35, .7);
    margin-top: 1rem;
    cursor: pointer;
}

.datepicker label {
    padding: 3px 7px;
    margin-bottom: 0;
    font-weight: 600;
    cursor: pointer;
}

.documentType label {
    width: inherit;
}

.journalQuality label {
    width: inherit;
}

.spreadsheet select,
.spreadsheet input {
    background-color: white !important;
}

@media screen and (min-width: 768px) {
    .month-picker>.rmp-container .rmp-popup.range {
        margin-left: -180px;
    }
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

.noticeableGreen {
    font-weight: 500;
    color: var(--link-hover-color);
    display: block;
}

.noticeableBlack {
    font-weight: 500;
    color: var(--body-text);
    display: block;
    margin-top: 5px;
}
