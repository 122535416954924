@supports (display:grid) {
    .grid {
        display: grid;
    }

    .gridCenter {
        align-items: center;
        justify-content: center;
    }

    .gridNav {
        grid-template-columns: 1fr 2fr;
        grid-gap: 40px;
    }

    .navButtons {
        grid-template-columns: 2fr 1fr;
        align-items: center;
        justify-content: flex-start;
        align-self: center;
        justify-self: center;
    }
}

@supports (display:-ms-grid) {
    .grid {
        display: -ms-grid;
    }

    .gridCenter {
        -ms-flex-align: center;
        -ms-flex-pack: center;
    }

    .gridNav {
        -ms-grid-columns: 1fr 2fr;
        grid-gap: 40px;
    }

    .navButtons {
        -ms-grid-columns: 2fr 1fr;
        -ms-flex-align: center;
        -ms-flex-pack: flex-start;
        -ms-grid-row-align: center;
        -ms-grid-column-align: center;
    }
}
