.contextTable {
    width: 100%;
    height: 100%;
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
    table-layout: fixed;
}

.tableBody {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
}
