.antiButton {
    border: none;
    outline: none;
    background: none;
}

.antiButton:focus {
    outline: none;
}

.redLink {
    background: none !important;
    border: none;
    padding: 0 !important;

    /*optional*/
    /* font-family: arial, sans-serif; input has OS specific font-family */
    color: #C33;
    cursor: pointer;
}

.redLink:hover {
    text-decoration: underline;
}

.alignLeftLink {
    text-align: left;
    vertical-align: text-top;
}

.buttonToLeftLink {
    composes: antiButton;
    composes: redLink;
    composes: alignLeftLink;
}

.buttonToLink {
    composes: antiButton;
    composes: redLink;
}

.btn {
    display: inline-block;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 2rem;
    margin: 2px;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
}

.btnPrimary {
    background-image: linear-gradient(-180deg, var(--primary-color) 0%, var(--link-hover-color) 100%);
    color: white;
    border: 1px solid transparent;
}

.btnPrimary:hover {
    background-image: none;
    background-color: #d6dade;
    color: var(--body-text);
    border: 1px solid var(--link-hover-color);
    border-bottom-color: var(--link-hover-color) !important;
}

.btnActionViolet {
    background-image: linear-gradient(-180deg, #ab80cd 0%, var(--action__color--violet) 100%);
    color: white;
}

.btnActionLightgray {
    background-color: #d6dade;
    color: var(--body-text);
    border: 1px solid var(--link-hover-color);
}

.btnActionLightgray:hover,
.btnActionLightgray:focus {
    background-image: linear-gradient(-180deg, var(--primary-color) 0%, var(--link-hover-color) 100%);
    color: white;
    border: 1px solid transparent;
}

.btnActionViolet:hover,
.btnActionViolet:focus {
    color: #f2f2f2;
    box-shadow: inset 0 -2px 0 rgba(22, 30, 37, 0.15)
}
