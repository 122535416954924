.SearchPage_wrapper__11VwA {
    width: 100%;
    height: auto;
}

.SearchPage_header__1mr3Y {
    width: 100%;
}

.SearchPage_header__1mr3Y h2 {
    color: white;
    text-align: center;
    font-size: 4rem;
    font-weight: 400;
}

.SearchPage_header__1mr3Y a {
    text-align: center;
}

.SearchPage_particlesJs__1-Y3p {
    width: 100%;
    height: 340px;
    max-height: 400px;
    background-size: cover;
    background: #212000 no-repeat 50% 50%;
    /* padding-top: 4rem;
    padding-bottom: 2rem; */
    text-align: center;
}

.SearchPage_heroCaption__KGK9w {
    position: absolute;
    top: 25%;
    display: block;
    background: transparent;
    width: 100%;
    z-index: 100;
}

canvas {
    display: block;
    vertical-align: top;
}

.SearchPage_header__1mr3Y canvas {
    position: relative;
}

.SearchPage_antiButton__2kJ6m {
    border: none;
    outline: none;
    background: none;
}

.SearchPage_antiButton__2kJ6m:focus {
    outline: none;
}

.SearchPage_offset-20__3naRG {
    padding-left: 20px;
}

.SearchPage_float-right-600__2gfp0 {
    float: right;
}

@media (max-width: 1100px) {
    .SearchPage_float-right-600__2gfp0 {
        float: left;
    }
}

.SearchPage_noMarginNoPadding__2iE7B {
    margin: 0;
    padding: 0;
}

.SearchPage_d-flex__3UeWv {
    display: flex;
}

/* grid */

@media screen and (min-width: 768px) {
    @supports (display: grid) {
        .SearchPage_grid4__2LQil {
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 20px;
        }
        .SearchPage_grid2__2sHdT {
            grid-template-columns: 1fr 1fr;
            grid-gap: 20px;
        }
    }
    @supports (display: -ms-grid) {
        .SearchPage_grid4__2LQil {
            -ms-grid-columns: 1fr 1fr 1fr 1fr;
            grid-gap: 20px;
        }
        .SearchPage_grid2__2sHdT {
            -ms-grid-columns: 1fr 1fr;
            grid-gap: 20px;
        }
    }
}

@media screen and (max-width: 768px) {
    @supports (display: -ms-grid) {
        .SearchPage_grid4__2LQil {
            -ms-grid-rows: 1fr 1fr 1fr 1fr;
            grid-gap: 20px;
        }
        .SearchPage_grid2__2sHdT {
            -ms-grid-rows: 1fr 1fr;
            grid-gap: 20px;
        }
    }
    @supports (display: grid) {
        .SearchPage_grid4__2LQil {
            grid-template-rows: repeat(4, 1fr);
            grid-gap: 20px;
        }
        .SearchPage_grid2__2sHdT {
            grid-template-rows: 1fr 1fr;
            grid-gap: 20px;
        }
    }
}

/* Offer */

.SearchPage_offer__cy7-F {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    background-color: white;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.SearchPage_title__1jqeh {
    letter-spacing: 1.5px;
    font-size: 1.75rem;
    font-weight: 400;
    margin-bottom: 24px;
}

.SearchPage_titleSmall__1Sh3E {
    font-size: 1.5rem !important;
}

.SearchPage_titleSub__1aP-3 {
    font-size: 1.1rem !important;
    color: var(--body-text-light);
}

.SearchPage_offerSvg__372Ea {
    text-align: center;
    padding: 1rem 0;
}

.SearchPage_offerText__3GMH1 {
    text-align: center;
    height: 150px;
    vertical-align: middle;
    font-weight: 500;
}

@media screen and (max-width: 768px) {
    .SearchPage_offer__cy7-F {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
    .SearchPage_offerText__3GMH1 {
        height: 50px;
    }
}

/* exploration parameters */

.SearchPage_parameters__3ou1V {
    width: 100%;
}

.SearchPage_parametersBoxed__1aTT7 {
    width: 90%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    padding: 2rem 1.653rem;
    min-height: 500px;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, 0.2);
}

.SearchPage_parametersValues__32Slq {
    /* border: 1px solid rgba(0,0,0,.20); */
    background-color: #eff4f6;
}

.SearchPage_parametersBoxed__1aTT7 label {
    width: 100%;
    padding: 10px 4px;
}

/* TODO: apply exploration to all non button inputs */
.SearchPage_exploration__2U4pW input:not([type="button"]):not([type="checkbox"]):not([type="submit"]):not([type="file"]),
.SearchPage_exploration__2U4pW select {
    width: 90%;
    background: hsl(192, 26%, 95%);
    padding: 7px 12px;
    border: 0px solid transparent;
    border-bottom: 1px solid rgba(29, 146, 35, .7);
    /* box-shadow: 3px 2px 4px 0 hsla(0, 0%, 0%, 0.2); */
}

.SearchPage_exploration__2U4pW input[type="file"] {
    background: transparent;
    border-bottom-color: transparent;
    margin-top: 10px;
}

.SearchPage_exploration__2U4pW input[type="file"]:hover {
    border-bottom-color: transparent;
}

.SearchPage_exploration__2U4pW input:hover,
.SearchPage_exploration__2U4pW input:focus,
.SearchPage_exploration__2U4pW select:hover,
.SearchPage_exploration__2U4pW select:focus,
.SearchPage_datepicker__2EBtw:hover {
    border-bottom: 1px solid #9d63c9;
}

.SearchPage_exploration__2U4pW select[name="selectIssue"] {
    border: 1px solid #d3d3d3;
    margin-top: 10px;
    background-color: white;
}

.SearchPage_boxedTitle__1hOEb {
    font-size: 1.0542rem;
}

.SearchPage_boxedTitleSub__2KWFS {
    font-size: 1.0532rem;
    /* color: #5f3382; */
}

.SearchPage_boxedTitleSub__2KWFS button {
    margin-left: 15px;
    padding: 2px 25px;
}

.SearchPage_datepicker__2EBtw {
    width: 90%;
    background: white;
    border-bottom: 1px solid rgba(29, 146, 35, .7);
    margin-top: 1rem;
    cursor: pointer;
}

.SearchPage_datepicker__2EBtw label {
    padding: 3px 7px;
    margin-bottom: 0;
    font-weight: 600;
    cursor: pointer;
}

.SearchPage_documentType__1p1Eo label {
    width: inherit;
}

.SearchPage_journalQuality__3x5ul label {
    width: inherit;
}

.SearchPage_spreadsheet__1QLn7 select,
.SearchPage_spreadsheet__1QLn7 input {
    background-color: white !important;
}

@media screen and (min-width: 768px) {
    .SearchPage_month-picker__11B5r>.SearchPage_rmp-container__1N2gu .SearchPage_rmp-popup__36mT0.SearchPage_range__2hrPt {
        margin-left: -180px;
    }
}

.SearchPage_container__2-9nu {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .SearchPage_container__2-9nu {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .SearchPage_container__2-9nu {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .SearchPage_container__2-9nu {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .SearchPage_container__2-9nu {
        max-width: 1140px;
    }
}

.SearchPage_noticeableGreen__2YYgK {
    font-weight: 500;
    color: var(--link-hover-color);
    display: block;
}

.SearchPage_noticeableBlack__1CVP- {
    font-weight: 500;
    color: var(--body-text);
    display: block;
    margin-top: 5px;
}


.marginsAndPaddings_mw-100__3hjks {
    max-width: 100% !important;
}

.marginsAndPaddings_mh-100__3qtGb {
    max-height: 100% !important;
}

.marginsAndPaddings_m-0__1gbc1 {
    margin: 0 !important;
}

.marginsAndPaddings_mt-0__32kf8,
.marginsAndPaddings_my-0__1tVqE {
    margin-top: 0 !important;
}

.marginsAndPaddings_mr-0__3ekn6,
.marginsAndPaddings_mx-0__1e2Sr {
    margin-right: 0 !important;
}

.marginsAndPaddings_mb-0__3ypRO,
.marginsAndPaddings_my-0__1tVqE {
    margin-bottom: 0 !important;
}

.marginsAndPaddings_ml-0__3JepX,
.marginsAndPaddings_mx-0__1e2Sr {
    margin-left: 0 !important;
}

.marginsAndPaddings_m-1__3aLe7 {
    margin: 0.25rem !important;
}

.marginsAndPaddings_mt-1__szEkC,
.marginsAndPaddings_my-1__1lD4v {
    margin-top: 0.25rem !important;
}

.marginsAndPaddings_mr-1__1YAQF,
.marginsAndPaddings_mx-1__312U0 {
    margin-right: 0.25rem !important;
}

.marginsAndPaddings_mb-1__28-iG,
.marginsAndPaddings_my-1__1lD4v {
    margin-bottom: 0.25rem !important;
}

.marginsAndPaddings_ml-1__3APUp,
.marginsAndPaddings_mx-1__312U0 {
    margin-left: 0.25rem !important;
}

.marginsAndPaddings_m-2__Gk8WD {
    margin: 0.5rem !important;
}

.marginsAndPaddings_mt-2__1_CP5,
.marginsAndPaddings_my-2__19lNL {
    margin-top: 0.5rem !important;
}

.marginsAndPaddings_mr-2__1X9zt,
.marginsAndPaddings_mx-2__bGekh {
    margin-right: 0.5rem !important;
}

.marginsAndPaddings_mb-2__6l2ry,
.marginsAndPaddings_my-2__19lNL {
    margin-bottom: 0.5rem !important;
}

.marginsAndPaddings_ml-2__2igmt,
.marginsAndPaddings_mx-2__bGekh {
    margin-left: 0.5rem !important;
}

.marginsAndPaddings_m-3__LFZ2L {
    margin: 1rem !important;
}

.marginsAndPaddings_mt-3__23IY-,
.marginsAndPaddings_my-3__2Tp9H {
    margin-top: 1rem !important;
}

.marginsAndPaddings_mr-3__1pvQx,
.marginsAndPaddings_mx-3__1-i23 {
    margin-right: 1rem !important;
}

.marginsAndPaddings_mb-3__tLnhd,
.marginsAndPaddings_my-3__2Tp9H {
    margin-bottom: 1rem !important;
}

.marginsAndPaddings_ml-3__1kZVz,
.marginsAndPaddings_mx-3__1-i23 {
    margin-left: 1rem !important;
}

.marginsAndPaddings_m-4__JD6QS {
    margin: 1.5rem !important;
}

.marginsAndPaddings_mt-4__3TkAv,
.marginsAndPaddings_my-4__2WvCM {
    margin-top: 1.5rem !important;
}

.marginsAndPaddings_mr-4__3U-N0,
.marginsAndPaddings_mx-4__2hIce {
    margin-right: 1.5rem !important;
}

.marginsAndPaddings_mb-4__3zc53,
.marginsAndPaddings_my-4__2WvCM {
    margin-bottom: 1.5rem !important;
}

.marginsAndPaddings_ml-4__DDhzU,
.marginsAndPaddings_mx-4__2hIce {
    margin-left: 1.5rem !important;
}

.marginsAndPaddings_m-5__3Rs1R {
    margin: 3rem !important;
}

.marginsAndPaddings_mt-5__34yxp,
.marginsAndPaddings_my-5__3hNXW {
    margin-top: 3rem !important;
}

.marginsAndPaddings_mr-5__CR3dX,
.marginsAndPaddings_mx-5__3izpM {
    margin-right: 3rem !important;
}

.marginsAndPaddings_mb-5__2TyAi,
.marginsAndPaddings_my-5__3hNXW {
    margin-bottom: 3rem !important;
}

.marginsAndPaddings_ml-5__2u2kE,
.marginsAndPaddings_mx-5__3izpM {
    margin-left: 3rem !important;
}

.marginsAndPaddings_p-0__31vlo {
    padding: 0 !important;
}

.marginsAndPaddings_pt-0__UIOK6,
.marginsAndPaddings_py-0__1FfGV {
    padding-top: 0 !important;
}

.marginsAndPaddings_pr-0__8InZv,
.marginsAndPaddings_px-0__28vSU {
    padding-right: 0 !important;
}

.marginsAndPaddings_pb-0__2QLKL,
.marginsAndPaddings_py-0__1FfGV {
    padding-bottom: 0 !important;
}

.marginsAndPaddings_pl-0__330Pm,
.marginsAndPaddings_px-0__28vSU {
    padding-left: 0 !important;
}

.marginsAndPaddings_p-1__38Rcg {
    padding: 0.25rem !important;
}

.marginsAndPaddings_pt-1__2V6k5,
.marginsAndPaddings_py-1__1DXOY {
    padding-top: 0.25rem !important;
}

.marginsAndPaddings_pr-1__1jGHQ,
.marginsAndPaddings_px-1__2JfXQ {
    padding-right: 0.25rem !important;
}

.marginsAndPaddings_pb-1__ULbp9,
.marginsAndPaddings_py-1__1DXOY {
    padding-bottom: 0.25rem !important;
}

.marginsAndPaddings_pl-1__18a8v,
.marginsAndPaddings_px-1__2JfXQ {
    padding-left: 0.25rem !important;
}

.marginsAndPaddings_p-2__2CZJl {
    padding: 0.5rem !important;
}

.marginsAndPaddings_pt-2__jCJcm,
.marginsAndPaddings_py-2__v7U-O {
    padding-top: 0.5rem !important;
}

.marginsAndPaddings_pr-2__2XJNx,
.marginsAndPaddings_px-2__2bTsl {
    padding-right: 0.5rem !important;
}

.marginsAndPaddings_pb-2__3CRFB,
.marginsAndPaddings_py-2__v7U-O {
    padding-bottom: 0.5rem !important;
}

.marginsAndPaddings_pl-2__1OJRT,
.marginsAndPaddings_px-2__2bTsl {
    padding-left: 0.5rem !important;
}

.marginsAndPaddings_p-3__2k1So {
    padding: 1rem !important;
}

.marginsAndPaddings_pt-3__1E7dB,
.marginsAndPaddings_py-3__23PHv {
    padding-top: 1rem !important;
}

.marginsAndPaddings_pr-3__3nz2m,
.marginsAndPaddings_px-3__2YPxI {
    padding-right: 1rem !important;
}

.marginsAndPaddings_pb-3__UkAjK,
.marginsAndPaddings_py-3__23PHv {
    padding-bottom: 1rem !important;
}

.marginsAndPaddings_pl-3__3xaM3,
.marginsAndPaddings_px-3__2YPxI {
    padding-left: 1rem !important;
}

.marginsAndPaddings_p-4__3pDN8 {
    padding: 1.5rem !important;
}

.marginsAndPaddings_pt-4__3mYSb,
.marginsAndPaddings_py-4__MD7Cm {
    padding-top: 1.5rem !important;
}

.marginsAndPaddings_pr-4__2xEB7,
.marginsAndPaddings_px-4__HhKha {
    padding-right: 1.5rem !important;
}

.marginsAndPaddings_pb-4__1zdSY,
.marginsAndPaddings_py-4__MD7Cm {
    padding-bottom: 1.5rem !important;
}

.marginsAndPaddings_pl-4__12MSd,
.marginsAndPaddings_px-4__HhKha {
    padding-left: 1.5rem !important;
}

.marginsAndPaddings_p-5__dVSu5 {
    padding: 3rem !important;
}

.marginsAndPaddings_pt-5__1Ro_q,
.marginsAndPaddings_py-5__J06gP {
    padding-top: 3rem !important;
}

.marginsAndPaddings_pr-5__NihxS,
.marginsAndPaddings_px-5__DzTox {
    padding-right: 3rem !important;
}

.marginsAndPaddings_pb-5__1hLVx,
.marginsAndPaddings_py-5__J06gP {
    padding-bottom: 3rem !important;
}

.marginsAndPaddings_pl-5__1ppPU,
.marginsAndPaddings_px-5__DzTox {
    padding-left: 3rem !important;
}

.marginsAndPaddings_m-auto__2tFJS {
    margin: auto !important;
}

.marginsAndPaddings_mt-auto__35F-j,
.marginsAndPaddings_my-auto__2IcWr {
    margin-top: auto !important;
}

.marginsAndPaddings_mr-auto__7FbSe,
.marginsAndPaddings_mx-auto__3xPtO {
    margin-right: auto !important;
}

.marginsAndPaddings_mb-auto__neo5n,
.marginsAndPaddings_my-auto__2IcWr {
    margin-bottom: auto !important;
}

.marginsAndPaddings_ml-auto__1vZFA,
.marginsAndPaddings_mx-auto__3xPtO {
    margin-left: auto !important;
}

@media (min-width: 576px) {
    .marginsAndPaddings_m-sm-0__1AVwG {
        margin: 0 !important;
    }

    .marginsAndPaddings_mt-sm-0__2a2Fk,
    .marginsAndPaddings_my-sm-0__PTM1T {
        margin-top: 0 !important;
    }

    .marginsAndPaddings_mr-sm-0__1vQBL,
    .marginsAndPaddings_mx-sm-0__1JFFh {
        margin-right: 0 !important;
    }

    .marginsAndPaddings_mb-sm-0__1CTK_,
    .marginsAndPaddings_my-sm-0__PTM1T {
        margin-bottom: 0 !important;
    }

    .marginsAndPaddings_ml-sm-0__6XtMR,
    .marginsAndPaddings_mx-sm-0__1JFFh {
        margin-left: 0 !important;
    }

    .marginsAndPaddings_m-sm-1__2I8GV {
        margin: 0.25rem !important;
    }

    .marginsAndPaddings_mt-sm-1__2igUC,
    .marginsAndPaddings_my-sm-1__1LjSb {
        margin-top: 0.25rem !important;
    }

    .marginsAndPaddings_mr-sm-1__3gKCB,
    .marginsAndPaddings_mx-sm-1__1xZ1A {
        margin-right: 0.25rem !important;
    }

    .marginsAndPaddings_mb-sm-1__1GBNM,
    .marginsAndPaddings_my-sm-1__1LjSb {
        margin-bottom: 0.25rem !important;
    }

    .marginsAndPaddings_ml-sm-1__3RuvC,
    .marginsAndPaddings_mx-sm-1__1xZ1A {
        margin-left: 0.25rem !important;
    }

    .marginsAndPaddings_m-sm-2__1RKOK {
        margin: 0.5rem !important;
    }

    .marginsAndPaddings_mt-sm-2__15x1E,
    .marginsAndPaddings_my-sm-2__1HMTr {
        margin-top: 0.5rem !important;
    }

    .marginsAndPaddings_mr-sm-2__2WV9j,
    .marginsAndPaddings_mx-sm-2__3e9vv {
        margin-right: 0.5rem !important;
    }

    .marginsAndPaddings_mb-sm-2__1MZDu,
    .marginsAndPaddings_my-sm-2__1HMTr {
        margin-bottom: 0.5rem !important;
    }

    .marginsAndPaddings_ml-sm-2__12gsW,
    .marginsAndPaddings_mx-sm-2__3e9vv {
        margin-left: 0.5rem !important;
    }

    .marginsAndPaddings_m-sm-3__1GjnL {
        margin: 1rem !important;
    }

    .marginsAndPaddings_mt-sm-3__2SgAn,
    .marginsAndPaddings_my-sm-3___h8CZ {
        margin-top: 1rem !important;
    }

    .marginsAndPaddings_mr-sm-3__3cyMg,
    .marginsAndPaddings_mx-sm-3__RFuTI {
        margin-right: 1rem !important;
    }

    .marginsAndPaddings_mb-sm-3__36IFj,
    .marginsAndPaddings_my-sm-3___h8CZ {
        margin-bottom: 1rem !important;
    }

    .marginsAndPaddings_ml-sm-3__mllWU,
    .marginsAndPaddings_mx-sm-3__RFuTI {
        margin-left: 1rem !important;
    }

    .marginsAndPaddings_m-sm-4__1ABth {
        margin: 1.5rem !important;
    }

    .marginsAndPaddings_mt-sm-4__1nvuW,
    .marginsAndPaddings_my-sm-4__1PPK_ {
        margin-top: 1.5rem !important;
    }

    .marginsAndPaddings_mr-sm-4__32suO,
    .marginsAndPaddings_mx-sm-4__1VPjI {
        margin-right: 1.5rem !important;
    }

    .marginsAndPaddings_mb-sm-4__MpSEl,
    .marginsAndPaddings_my-sm-4__1PPK_ {
        margin-bottom: 1.5rem !important;
    }

    .marginsAndPaddings_ml-sm-4__2ZVKS,
    .marginsAndPaddings_mx-sm-4__1VPjI {
        margin-left: 1.5rem !important;
    }

    .marginsAndPaddings_m-sm-5__vBayv {
        margin: 3rem !important;
    }

    .marginsAndPaddings_mt-sm-5__3EoOp,
    .marginsAndPaddings_my-sm-5__3WDD5 {
        margin-top: 3rem !important;
    }

    .marginsAndPaddings_mr-sm-5__1iJwH,
    .marginsAndPaddings_mx-sm-5__qcOgi {
        margin-right: 3rem !important;
    }

    .marginsAndPaddings_mb-sm-5__FVrRz,
    .marginsAndPaddings_my-sm-5__3WDD5 {
        margin-bottom: 3rem !important;
    }

    .marginsAndPaddings_ml-sm-5__1-ihj,
    .marginsAndPaddings_mx-sm-5__qcOgi {
        margin-left: 3rem !important;
    }

    .marginsAndPaddings_p-sm-0__2cuIf {
        padding: 0 !important;
    }

    .marginsAndPaddings_pt-sm-0__3AeV3,
    .marginsAndPaddings_py-sm-0__JBdcv {
        padding-top: 0 !important;
    }

    .marginsAndPaddings_pr-sm-0__12JxK,
    .marginsAndPaddings_px-sm-0__1S12_ {
        padding-right: 0 !important;
    }

    .marginsAndPaddings_pb-sm-0__2IevF,
    .marginsAndPaddings_py-sm-0__JBdcv {
        padding-bottom: 0 !important;
    }

    .marginsAndPaddings_pl-sm-0__3j3oE,
    .marginsAndPaddings_px-sm-0__1S12_ {
        padding-left: 0 !important;
    }

    .marginsAndPaddings_p-sm-1__IrBf8 {
        padding: 0.25rem !important;
    }

    .marginsAndPaddings_pt-sm-1__12Xgj,
    .marginsAndPaddings_py-sm-1__1u6M5 {
        padding-top: 0.25rem !important;
    }

    .marginsAndPaddings_pr-sm-1__3P8bj,
    .marginsAndPaddings_px-sm-1__3RFlJ {
        padding-right: 0.25rem !important;
    }

    .marginsAndPaddings_pb-sm-1__2lhkw,
    .marginsAndPaddings_py-sm-1__1u6M5 {
        padding-bottom: 0.25rem !important;
    }

    .marginsAndPaddings_pl-sm-1__qoR5_,
    .marginsAndPaddings_px-sm-1__3RFlJ {
        padding-left: 0.25rem !important;
    }

    .marginsAndPaddings_p-sm-2__3VV9v {
        padding: 0.5rem !important;
    }

    .marginsAndPaddings_pt-sm-2__1fMi9,
    .marginsAndPaddings_py-sm-2__w_ZmP {
        padding-top: 0.5rem !important;
    }

    .marginsAndPaddings_pr-sm-2__1Ex9_,
    .marginsAndPaddings_px-sm-2__CAS9S {
        padding-right: 0.5rem !important;
    }

    .marginsAndPaddings_pb-sm-2__118ko,
    .marginsAndPaddings_py-sm-2__w_ZmP {
        padding-bottom: 0.5rem !important;
    }

    .marginsAndPaddings_pl-sm-2__tIJHG,
    .marginsAndPaddings_px-sm-2__CAS9S {
        padding-left: 0.5rem !important;
    }

    .marginsAndPaddings_p-sm-3__38vUX {
        padding: 1rem !important;
    }

    .marginsAndPaddings_pt-sm-3__3kS6P,
    .marginsAndPaddings_py-sm-3__1CBaG {
        padding-top: 1rem !important;
    }

    .marginsAndPaddings_pr-sm-3__26bsF,
    .marginsAndPaddings_px-sm-3__OiYWi {
        padding-right: 1rem !important;
    }

    .marginsAndPaddings_pb-sm-3__1yeEO,
    .marginsAndPaddings_py-sm-3__1CBaG {
        padding-bottom: 1rem !important;
    }

    .marginsAndPaddings_pl-sm-3__1q_MU,
    .marginsAndPaddings_px-sm-3__OiYWi {
        padding-left: 1rem !important;
    }

    .marginsAndPaddings_p-sm-4__27uLu {
        padding: 1.5rem !important;
    }

    .marginsAndPaddings_pt-sm-4__2uVA1,
    .marginsAndPaddings_py-sm-4__3wYbe {
        padding-top: 1.5rem !important;
    }

    .marginsAndPaddings_pr-sm-4__w0uzC,
    .marginsAndPaddings_px-sm-4__31reM {
        padding-right: 1.5rem !important;
    }

    .marginsAndPaddings_pb-sm-4__2NtNe,
    .marginsAndPaddings_py-sm-4__3wYbe {
        padding-bottom: 1.5rem !important;
    }

    .marginsAndPaddings_pl-sm-4__8GmYy,
    .marginsAndPaddings_px-sm-4__31reM {
        padding-left: 1.5rem !important;
    }

    .marginsAndPaddings_p-sm-5__36xIK {
        padding: 3rem !important;
    }

    .marginsAndPaddings_pt-sm-5__1pBRi,
    .marginsAndPaddings_py-sm-5__3CDvg {
        padding-top: 3rem !important;
    }

    .marginsAndPaddings_pr-sm-5__1-dl8,
    .marginsAndPaddings_px-sm-5__2bnn9 {
        padding-right: 3rem !important;
    }

    .marginsAndPaddings_pb-sm-5__2NtO7,
    .marginsAndPaddings_py-sm-5__3CDvg {
        padding-bottom: 3rem !important;
    }

    .marginsAndPaddings_pl-sm-5__LI9ii,
    .marginsAndPaddings_px-sm-5__2bnn9 {
        padding-left: 3rem !important;
    }

    .marginsAndPaddings_m-sm-auto__2RtIK {
        margin: auto !important;
    }

    .marginsAndPaddings_mt-sm-auto__3z1u-,
    .marginsAndPaddings_my-sm-auto__1mMWR {
        margin-top: auto !important;
    }

    .marginsAndPaddings_mr-sm-auto__3S0Bn,
    .marginsAndPaddings_mx-sm-auto__3k-3s {
        margin-right: auto !important;
    }

    .marginsAndPaddings_mb-sm-auto__2z9_p,
    .marginsAndPaddings_my-sm-auto__1mMWR {
        margin-bottom: auto !important;
    }

    .marginsAndPaddings_ml-sm-auto__mcAJk,
    .marginsAndPaddings_mx-sm-auto__3k-3s {
        margin-left: auto !important;
    }
}

@media (min-width: 768px) {
    .marginsAndPaddings_m-md-0__1uprk {
        margin: 0 !important;
    }

    .marginsAndPaddings_mt-md-0__2nGbD,
    .marginsAndPaddings_my-md-0___-RZ6 {
        margin-top: 0 !important;
    }

    .marginsAndPaddings_mr-md-0__1-QTo,
    .marginsAndPaddings_mx-md-0__2oeck {
        margin-right: 0 !important;
    }

    .marginsAndPaddings_mb-md-0__1u_44,
    .marginsAndPaddings_my-md-0___-RZ6 {
        margin-bottom: 0 !important;
    }

    .marginsAndPaddings_ml-md-0__2Jx7c,
    .marginsAndPaddings_mx-md-0__2oeck {
        margin-left: 0 !important;
    }

    .marginsAndPaddings_m-md-1__3Qvp2 {
        margin: 0.25rem !important;
    }

    .marginsAndPaddings_mt-md-1__Kaejz,
    .marginsAndPaddings_my-md-1__m8j8o {
        margin-top: 0.25rem !important;
    }

    .marginsAndPaddings_mr-md-1__1HjTp,
    .marginsAndPaddings_mx-md-1__2Wjam {
        margin-right: 0.25rem !important;
    }

    .marginsAndPaddings_mb-md-1__1k_Sz,
    .marginsAndPaddings_my-md-1__m8j8o {
        margin-bottom: 0.25rem !important;
    }

    .marginsAndPaddings_ml-md-1__eMbdv,
    .marginsAndPaddings_mx-md-1__2Wjam {
        margin-left: 0.25rem !important;
    }

    .marginsAndPaddings_m-md-2__360ED {
        margin: 0.5rem !important;
    }

    .marginsAndPaddings_mt-md-2__Y0pDn,
    .marginsAndPaddings_my-md-2__2irGN {
        margin-top: 0.5rem !important;
    }

    .marginsAndPaddings_mr-md-2__2zw7R,
    .marginsAndPaddings_mx-md-2__-zceT {
        margin-right: 0.5rem !important;
    }

    .marginsAndPaddings_mb-md-2__VHMlH,
    .marginsAndPaddings_my-md-2__2irGN {
        margin-bottom: 0.5rem !important;
    }

    .marginsAndPaddings_ml-md-2__9ipk0,
    .marginsAndPaddings_mx-md-2__-zceT {
        margin-left: 0.5rem !important;
    }

    .marginsAndPaddings_m-md-3__oHRzO {
        margin: 1rem !important;
    }

    .marginsAndPaddings_mt-md-3__3EykI,
    .marginsAndPaddings_my-md-3__3syDR {
        margin-top: 1rem !important;
    }

    .marginsAndPaddings_mr-md-3__3mtNU,
    .marginsAndPaddings_mx-md-3__109ID {
        margin-right: 1rem !important;
    }

    .marginsAndPaddings_mb-md-3__1HSwf,
    .marginsAndPaddings_my-md-3__3syDR {
        margin-bottom: 1rem !important;
    }

    .marginsAndPaddings_ml-md-3__3PyrD,
    .marginsAndPaddings_mx-md-3__109ID {
        margin-left: 1rem !important;
    }

    .marginsAndPaddings_m-md-4__18ivL {
        margin: 1.5rem !important;
    }

    .marginsAndPaddings_mt-md-4__1Ze4-,
    .marginsAndPaddings_my-md-4__2y0Rx {
        margin-top: 1.5rem !important;
    }

    .marginsAndPaddings_mr-md-4__31D4F,
    .marginsAndPaddings_mx-md-4__SqYrC {
        margin-right: 1.5rem !important;
    }

    .marginsAndPaddings_mb-md-4__5_lc3,
    .marginsAndPaddings_my-md-4__2y0Rx {
        margin-bottom: 1.5rem !important;
    }

    .marginsAndPaddings_ml-md-4__1m3Jg,
    .marginsAndPaddings_mx-md-4__SqYrC {
        margin-left: 1.5rem !important;
    }

    .marginsAndPaddings_m-md-5__10vgG {
        margin: 3rem !important;
    }

    .marginsAndPaddings_mt-md-5__1ra-T,
    .marginsAndPaddings_my-md-5__ocxEH {
        margin-top: 3rem !important;
    }

    .marginsAndPaddings_mr-md-5__3e2wl,
    .marginsAndPaddings_mx-md-5__3ur1m {
        margin-right: 3rem !important;
    }

    .marginsAndPaddings_mb-md-5__Uje_z,
    .marginsAndPaddings_my-md-5__ocxEH {
        margin-bottom: 3rem !important;
    }

    .marginsAndPaddings_ml-md-5__H3JR7,
    .marginsAndPaddings_mx-md-5__3ur1m {
        margin-left: 3rem !important;
    }

    .marginsAndPaddings_p-md-0__2gZ_D {
        padding: 0 !important;
    }

    .marginsAndPaddings_pt-md-0__28jpK,
    .marginsAndPaddings_py-md-0__1N3Ag {
        padding-top: 0 !important;
    }

    .marginsAndPaddings_pr-md-0__SfM00,
    .marginsAndPaddings_px-md-0__1ndGm {
        padding-right: 0 !important;
    }

    .marginsAndPaddings_pb-md-0__2GVJu,
    .marginsAndPaddings_py-md-0__1N3Ag {
        padding-bottom: 0 !important;
    }

    .marginsAndPaddings_pl-md-0__thXGs,
    .marginsAndPaddings_px-md-0__1ndGm {
        padding-left: 0 !important;
    }

    .marginsAndPaddings_p-md-1__1n9Gt {
        padding: 0.25rem !important;
    }

    .marginsAndPaddings_pt-md-1__2zh9o,
    .marginsAndPaddings_py-md-1__1Owhr {
        padding-top: 0.25rem !important;
    }

    .marginsAndPaddings_pr-md-1__is6CP,
    .marginsAndPaddings_px-md-1__v6yZO {
        padding-right: 0.25rem !important;
    }

    .marginsAndPaddings_pb-md-1__3-NUJ,
    .marginsAndPaddings_py-md-1__1Owhr {
        padding-bottom: 0.25rem !important;
    }

    .marginsAndPaddings_pl-md-1__Tbx8t,
    .marginsAndPaddings_px-md-1__v6yZO {
        padding-left: 0.25rem !important;
    }

    .marginsAndPaddings_p-md-2__glAA9 {
        padding: 0.5rem !important;
    }

    .marginsAndPaddings_pt-md-2__jvUch,
    .marginsAndPaddings_py-md-2__1Bhbd {
        padding-top: 0.5rem !important;
    }

    .marginsAndPaddings_pr-md-2__3-MSM,
    .marginsAndPaddings_px-md-2__TIQj8 {
        padding-right: 0.5rem !important;
    }

    .marginsAndPaddings_pb-md-2__FYDks,
    .marginsAndPaddings_py-md-2__1Bhbd {
        padding-bottom: 0.5rem !important;
    }

    .marginsAndPaddings_pl-md-2__2IqPG,
    .marginsAndPaddings_px-md-2__TIQj8 {
        padding-left: 0.5rem !important;
    }

    .marginsAndPaddings_p-md-3__2irY3 {
        padding: 1rem !important;
    }

    .marginsAndPaddings_pt-md-3__3nEIK,
    .marginsAndPaddings_py-md-3__2FJpM {
        padding-top: 1rem !important;
    }

    .marginsAndPaddings_pr-md-3__2NdWu,
    .marginsAndPaddings_px-md-3__1qmCI {
        padding-right: 1rem !important;
    }

    .marginsAndPaddings_pb-md-3__2sN3E,
    .marginsAndPaddings_py-md-3__2FJpM {
        padding-bottom: 1rem !important;
    }

    .marginsAndPaddings_pl-md-3__1EKXn,
    .marginsAndPaddings_px-md-3__1qmCI {
        padding-left: 1rem !important;
    }

    .marginsAndPaddings_p-md-4__3Mmi9 {
        padding: 1.5rem !important;
    }

    .marginsAndPaddings_pt-md-4__3hbtn,
    .marginsAndPaddings_py-md-4__1uM9l {
        padding-top: 1.5rem !important;
    }

    .marginsAndPaddings_pr-md-4__2SrOa,
    .marginsAndPaddings_px-md-4__Tf-De {
        padding-right: 1.5rem !important;
    }

    .marginsAndPaddings_pb-md-4__tINk4,
    .marginsAndPaddings_py-md-4__1uM9l {
        padding-bottom: 1.5rem !important;
    }

    .marginsAndPaddings_pl-md-4__4qeOU,
    .marginsAndPaddings_px-md-4__Tf-De {
        padding-left: 1.5rem !important;
    }

    .marginsAndPaddings_p-md-5__2Es1A {
        padding: 3rem !important;
    }

    .marginsAndPaddings_pt-md-5__1c3MY,
    .marginsAndPaddings_py-md-5__1vrKt {
        padding-top: 3rem !important;
    }

    .marginsAndPaddings_pr-md-5__1uodp,
    .marginsAndPaddings_px-md-5__Nu3RC {
        padding-right: 3rem !important;
    }

    .marginsAndPaddings_pb-md-5__17hLp,
    .marginsAndPaddings_py-md-5__1vrKt {
        padding-bottom: 3rem !important;
    }

    .marginsAndPaddings_pl-md-5__jWzJe,
    .marginsAndPaddings_px-md-5__Nu3RC {
        padding-left: 3rem !important;
    }

    .marginsAndPaddings_m-md-auto__-CckG {
        margin: auto !important;
    }

    .marginsAndPaddings_mt-md-auto__20tOY,
    .marginsAndPaddings_my-md-auto__3jj_P {
        margin-top: auto !important;
    }

    .marginsAndPaddings_mr-md-auto__SIOTp,
    .marginsAndPaddings_mx-md-auto__2Ncm_ {
        margin-right: auto !important;
    }

    .marginsAndPaddings_mb-md-auto__Ky5J3,
    .marginsAndPaddings_my-md-auto__3jj_P {
        margin-bottom: auto !important;
    }

    .marginsAndPaddings_ml-md-auto__16DXs,
    .marginsAndPaddings_mx-md-auto__2Ncm_ {
        margin-left: auto !important;
    }
}

@media (min-width: 992px) {
    .marginsAndPaddings_m-lg-0__3Y1I9 {
        margin: 0 !important;
    }

    .marginsAndPaddings_mt-lg-0__1Cn-7,
    .marginsAndPaddings_my-lg-0__2WHjr {
        margin-top: 0 !important;
    }

    .marginsAndPaddings_mr-lg-0__2rD9H,
    .marginsAndPaddings_mx-lg-0__2nQ92 {
        margin-right: 0 !important;
    }

    .marginsAndPaddings_mb-lg-0__2AlFL,
    .marginsAndPaddings_my-lg-0__2WHjr {
        margin-bottom: 0 !important;
    }

    .marginsAndPaddings_ml-lg-0__35oiv,
    .marginsAndPaddings_mx-lg-0__2nQ92 {
        margin-left: 0 !important;
    }

    .marginsAndPaddings_m-lg-1__2ZJY7 {
        margin: 0.25rem !important;
    }

    .marginsAndPaddings_mt-lg-1__3j_Iy,
    .marginsAndPaddings_my-lg-1__3EeSL {
        margin-top: 0.25rem !important;
    }

    .marginsAndPaddings_mr-lg-1__21wK6,
    .marginsAndPaddings_mx-lg-1__3BKKE {
        margin-right: 0.25rem !important;
    }

    .marginsAndPaddings_mb-lg-1__1ofIu,
    .marginsAndPaddings_my-lg-1__3EeSL {
        margin-bottom: 0.25rem !important;
    }

    .marginsAndPaddings_ml-lg-1__2Ub3Q,
    .marginsAndPaddings_mx-lg-1__3BKKE {
        margin-left: 0.25rem !important;
    }

    .marginsAndPaddings_m-lg-2__1Es1d {
        margin: 0.5rem !important;
    }

    .marginsAndPaddings_mt-lg-2__1aqr0,
    .marginsAndPaddings_my-lg-2__2H7Hi {
        margin-top: 0.5rem !important;
    }

    .marginsAndPaddings_mr-lg-2__1atto,
    .marginsAndPaddings_mx-lg-2__2Wnh7 {
        margin-right: 0.5rem !important;
    }

    .marginsAndPaddings_mb-lg-2__1C2P8,
    .marginsAndPaddings_my-lg-2__2H7Hi {
        margin-bottom: 0.5rem !important;
    }

    .marginsAndPaddings_ml-lg-2__qF2fJ,
    .marginsAndPaddings_mx-lg-2__2Wnh7 {
        margin-left: 0.5rem !important;
    }

    .marginsAndPaddings_m-lg-3__1VL_o {
        margin: 1rem !important;
    }

    .marginsAndPaddings_mt-lg-3__CgPRn,
    .marginsAndPaddings_my-lg-3__dQCl0 {
        margin-top: 1rem !important;
    }

    .marginsAndPaddings_mr-lg-3__1ANyG,
    .marginsAndPaddings_mx-lg-3__Fxdlf {
        margin-right: 1rem !important;
    }

    .marginsAndPaddings_mb-lg-3__2mIbV,
    .marginsAndPaddings_my-lg-3__dQCl0 {
        margin-bottom: 1rem !important;
    }

    .marginsAndPaddings_ml-lg-3__2UhiA,
    .marginsAndPaddings_mx-lg-3__Fxdlf {
        margin-left: 1rem !important;
    }

    .marginsAndPaddings_m-lg-4__azvoE {
        margin: 1.5rem !important;
    }

    .marginsAndPaddings_mt-lg-4__3e_PK,
    .marginsAndPaddings_my-lg-4__33th1 {
        margin-top: 1.5rem !important;
    }

    .marginsAndPaddings_mr-lg-4__S9h9m,
    .marginsAndPaddings_mx-lg-4__3u31U {
        margin-right: 1.5rem !important;
    }

    .marginsAndPaddings_mb-lg-4__3TmC8,
    .marginsAndPaddings_my-lg-4__33th1 {
        margin-bottom: 1.5rem !important;
    }

    .marginsAndPaddings_ml-lg-4__3UB9X,
    .marginsAndPaddings_mx-lg-4__3u31U {
        margin-left: 1.5rem !important;
    }

    .marginsAndPaddings_m-lg-5__-0xuV {
        margin: 3rem !important;
    }

    .marginsAndPaddings_mt-lg-5__3t_jY,
    .marginsAndPaddings_my-lg-5__29UEG {
        margin-top: 3rem !important;
    }

    .marginsAndPaddings_mr-lg-5__3CKTc,
    .marginsAndPaddings_mx-lg-5__2krCk {
        margin-right: 3rem !important;
    }

    .marginsAndPaddings_mb-lg-5__34emY,
    .marginsAndPaddings_my-lg-5__29UEG {
        margin-bottom: 3rem !important;
    }

    .marginsAndPaddings_ml-lg-5__39aQO,
    .marginsAndPaddings_mx-lg-5__2krCk {
        margin-left: 3rem !important;
    }

    .marginsAndPaddings_p-lg-0__15rwn {
        padding: 0 !important;
    }

    .marginsAndPaddings_pt-lg-0__1mVX_,
    .marginsAndPaddings_py-lg-0__2LHgs {
        padding-top: 0 !important;
    }

    .marginsAndPaddings_pr-lg-0__RE1ys,
    .marginsAndPaddings_px-lg-0__38v_P {
        padding-right: 0 !important;
    }

    .marginsAndPaddings_pb-lg-0__3SJ1q,
    .marginsAndPaddings_py-lg-0__2LHgs {
        padding-bottom: 0 !important;
    }

    .marginsAndPaddings_pl-lg-0__3eYo1,
    .marginsAndPaddings_px-lg-0__38v_P {
        padding-left: 0 !important;
    }

    .marginsAndPaddings_p-lg-1__3fMWX {
        padding: 0.25rem !important;
    }

    .marginsAndPaddings_pt-lg-1__1IQbB,
    .marginsAndPaddings_py-lg-1__ihJNJ {
        padding-top: 0.25rem !important;
    }

    .marginsAndPaddings_pr-lg-1__WQmpD,
    .marginsAndPaddings_px-lg-1__1v0zz {
        padding-right: 0.25rem !important;
    }

    .marginsAndPaddings_pb-lg-1__Emp3-,
    .marginsAndPaddings_py-lg-1__ihJNJ {
        padding-bottom: 0.25rem !important;
    }

    .marginsAndPaddings_pl-lg-1__1-QmA,
    .marginsAndPaddings_px-lg-1__1v0zz {
        padding-left: 0.25rem !important;
    }

    .marginsAndPaddings_p-lg-2__1jJef {
        padding: 0.5rem !important;
    }

    .marginsAndPaddings_pt-lg-2__2Uts-,
    .marginsAndPaddings_py-lg-2__1KPQE {
        padding-top: 0.5rem !important;
    }

    .marginsAndPaddings_pr-lg-2__3MS-4,
    .marginsAndPaddings_px-lg-2__1-NJI {
        padding-right: 0.5rem !important;
    }

    .marginsAndPaddings_pb-lg-2__2MQRm,
    .marginsAndPaddings_py-lg-2__1KPQE {
        padding-bottom: 0.5rem !important;
    }

    .marginsAndPaddings_pl-lg-2__1fvUs,
    .marginsAndPaddings_px-lg-2__1-NJI {
        padding-left: 0.5rem !important;
    }

    .marginsAndPaddings_p-lg-3__306GC {
        padding: 1rem !important;
    }

    .marginsAndPaddings_pt-lg-3__1fVd1,
    .marginsAndPaddings_py-lg-3__2R6QP {
        padding-top: 1rem !important;
    }

    .marginsAndPaddings_pr-lg-3__3fCdt,
    .marginsAndPaddings_px-lg-3__2Gc3w {
        padding-right: 1rem !important;
    }

    .marginsAndPaddings_pb-lg-3__3ho1y,
    .marginsAndPaddings_py-lg-3__2R6QP {
        padding-bottom: 1rem !important;
    }

    .marginsAndPaddings_pl-lg-3__6d7QK,
    .marginsAndPaddings_px-lg-3__2Gc3w {
        padding-left: 1rem !important;
    }

    .marginsAndPaddings_p-lg-4__1_1UY {
        padding: 1.5rem !important;
    }

    .marginsAndPaddings_pt-lg-4__2Fy-h,
    .marginsAndPaddings_py-lg-4__2AKSF {
        padding-top: 1.5rem !important;
    }

    .marginsAndPaddings_pr-lg-4__3pVLV,
    .marginsAndPaddings_px-lg-4__18tFW {
        padding-right: 1.5rem !important;
    }

    .marginsAndPaddings_pb-lg-4__qC2JP,
    .marginsAndPaddings_py-lg-4__2AKSF {
        padding-bottom: 1.5rem !important;
    }

    .marginsAndPaddings_pl-lg-4__7SDEj,
    .marginsAndPaddings_px-lg-4__18tFW {
        padding-left: 1.5rem !important;
    }

    .marginsAndPaddings_p-lg-5__yHGZy {
        padding: 3rem !important;
    }

    .marginsAndPaddings_pt-lg-5__3ShQJ,
    .marginsAndPaddings_py-lg-5__35lFu {
        padding-top: 3rem !important;
    }

    .marginsAndPaddings_pr-lg-5__19wL6,
    .marginsAndPaddings_px-lg-5__38XIy {
        padding-right: 3rem !important;
    }

    .marginsAndPaddings_pb-lg-5__1ITwP,
    .marginsAndPaddings_py-lg-5__35lFu {
        padding-bottom: 3rem !important;
    }

    .marginsAndPaddings_pl-lg-5__3xJcM,
    .marginsAndPaddings_px-lg-5__38XIy {
        padding-left: 3rem !important;
    }

    .marginsAndPaddings_m-lg-auto__eeemQ {
        margin: auto !important;
    }

    .marginsAndPaddings_mt-lg-auto__1Ly0s,
    .marginsAndPaddings_my-lg-auto__3VCEN {
        margin-top: auto !important;
    }

    .marginsAndPaddings_mr-lg-auto__3dc4o,
    .marginsAndPaddings_mx-lg-auto__138lB {
        margin-right: auto !important;
    }

    .marginsAndPaddings_mb-lg-auto__1UsBS,
    .marginsAndPaddings_my-lg-auto__3VCEN {
        margin-bottom: auto !important;
    }

    .marginsAndPaddings_ml-lg-auto__18kw8,
    .marginsAndPaddings_mx-lg-auto__138lB {
        margin-left: auto !important;
    }
}

@media (min-width: 1200px) {
    .marginsAndPaddings_m-xl-0__2TfJU {
        margin: 0 !important;
    }

    .marginsAndPaddings_mt-xl-0__1jwEZ,
    .marginsAndPaddings_my-xl-0__1qKCd {
        margin-top: 0 !important;
    }

    .marginsAndPaddings_mr-xl-0__3Pv8Y,
    .marginsAndPaddings_mx-xl-0__1rj-6 {
        margin-right: 0 !important;
    }

    .marginsAndPaddings_mb-xl-0__107wB,
    .marginsAndPaddings_my-xl-0__1qKCd {
        margin-bottom: 0 !important;
    }

    .marginsAndPaddings_ml-xl-0__2ig7S,
    .marginsAndPaddings_mx-xl-0__1rj-6 {
        margin-left: 0 !important;
    }

    .marginsAndPaddings_m-xl-1__2NB5j {
        margin: 0.25rem !important;
    }

    .marginsAndPaddings_mt-xl-1__MuUoX,
    .marginsAndPaddings_my-xl-1__g48Tx {
        margin-top: 0.25rem !important;
    }

    .marginsAndPaddings_mr-xl-1__2bJLA,
    .marginsAndPaddings_mx-xl-1__yp3Vs {
        margin-right: 0.25rem !important;
    }

    .marginsAndPaddings_mb-xl-1__2kLsx,
    .marginsAndPaddings_my-xl-1__g48Tx {
        margin-bottom: 0.25rem !important;
    }

    .marginsAndPaddings_ml-xl-1__3Naqj,
    .marginsAndPaddings_mx-xl-1__yp3Vs {
        margin-left: 0.25rem !important;
    }

    .marginsAndPaddings_m-xl-2__3tpmA {
        margin: 0.5rem !important;
    }

    .marginsAndPaddings_mt-xl-2__3aeFo,
    .marginsAndPaddings_my-xl-2__ldsuB {
        margin-top: 0.5rem !important;
    }

    .marginsAndPaddings_mr-xl-2__1bpI2,
    .marginsAndPaddings_mx-xl-2__1oQCk {
        margin-right: 0.5rem !important;
    }

    .marginsAndPaddings_mb-xl-2__pjSxI,
    .marginsAndPaddings_my-xl-2__ldsuB {
        margin-bottom: 0.5rem !important;
    }

    .marginsAndPaddings_ml-xl-2__1Tj8_,
    .marginsAndPaddings_mx-xl-2__1oQCk {
        margin-left: 0.5rem !important;
    }

    .marginsAndPaddings_m-xl-3__3Hoaq {
        margin: 1rem !important;
    }

    .marginsAndPaddings_mt-xl-3__uURLZ,
    .marginsAndPaddings_my-xl-3__3P7PZ {
        margin-top: 1rem !important;
    }

    .marginsAndPaddings_mr-xl-3__2L1-B,
    .marginsAndPaddings_mx-xl-3__1lmfs {
        margin-right: 1rem !important;
    }

    .marginsAndPaddings_mb-xl-3__2t6xv,
    .marginsAndPaddings_my-xl-3__3P7PZ {
        margin-bottom: 1rem !important;
    }

    .marginsAndPaddings_ml-xl-3__2t42H,
    .marginsAndPaddings_mx-xl-3__1lmfs {
        margin-left: 1rem !important;
    }

    .marginsAndPaddings_m-xl-4__29z8Z {
        margin: 1.5rem !important;
    }

    .marginsAndPaddings_mt-xl-4__nKkNW,
    .marginsAndPaddings_my-xl-4__2kG8l {
        margin-top: 1.5rem !important;
    }

    .marginsAndPaddings_mr-xl-4__1RsYZ,
    .marginsAndPaddings_mx-xl-4__2vzzo {
        margin-right: 1.5rem !important;
    }

    .marginsAndPaddings_mb-xl-4__2Rc7U,
    .marginsAndPaddings_my-xl-4__2kG8l {
        margin-bottom: 1.5rem !important;
    }

    .marginsAndPaddings_ml-xl-4__324g0,
    .marginsAndPaddings_mx-xl-4__2vzzo {
        margin-left: 1.5rem !important;
    }

    .marginsAndPaddings_m-xl-5__3vDB6 {
        margin: 3rem !important;
    }

    .marginsAndPaddings_mt-xl-5__1X7ME,
    .marginsAndPaddings_my-xl-5__24-pu {
        margin-top: 3rem !important;
    }

    .marginsAndPaddings_mr-xl-5__AiGsc,
    .marginsAndPaddings_mx-xl-5__8Fh8b {
        margin-right: 3rem !important;
    }

    .marginsAndPaddings_mb-xl-5__24mhx,
    .marginsAndPaddings_my-xl-5__24-pu {
        margin-bottom: 3rem !important;
    }

    .marginsAndPaddings_ml-xl-5__32arf,
    .marginsAndPaddings_mx-xl-5__8Fh8b {
        margin-left: 3rem !important;
    }

    .marginsAndPaddings_p-xl-0__3QJ-- {
        padding: 0 !important;
    }

    .marginsAndPaddings_pt-xl-0__P7EuD,
    .marginsAndPaddings_py-xl-0__ChmjP {
        padding-top: 0 !important;
    }

    .marginsAndPaddings_pr-xl-0__1RU2z,
    .marginsAndPaddings_px-xl-0__3zcb_ {
        padding-right: 0 !important;
    }

    .marginsAndPaddings_pb-xl-0__1b9ht,
    .marginsAndPaddings_py-xl-0__ChmjP {
        padding-bottom: 0 !important;
    }

    .marginsAndPaddings_pl-xl-0__1UXMV,
    .marginsAndPaddings_px-xl-0__3zcb_ {
        padding-left: 0 !important;
    }

    .marginsAndPaddings_p-xl-1__23e1O {
        padding: 0.25rem !important;
    }

    .marginsAndPaddings_pt-xl-1__1DD9c,
    .marginsAndPaddings_py-xl-1__F25MH {
        padding-top: 0.25rem !important;
    }

    .marginsAndPaddings_pr-xl-1__2a4xH,
    .marginsAndPaddings_px-xl-1__3XTu4 {
        padding-right: 0.25rem !important;
    }

    .marginsAndPaddings_pb-xl-1__3PIDc,
    .marginsAndPaddings_py-xl-1__F25MH {
        padding-bottom: 0.25rem !important;
    }

    .marginsAndPaddings_pl-xl-1__2J-FU,
    .marginsAndPaddings_px-xl-1__3XTu4 {
        padding-left: 0.25rem !important;
    }

    .marginsAndPaddings_p-xl-2__RaWzR {
        padding: 0.5rem !important;
    }

    .marginsAndPaddings_pt-xl-2__3dKHq,
    .marginsAndPaddings_py-xl-2__3aJBI {
        padding-top: 0.5rem !important;
    }

    .marginsAndPaddings_pr-xl-2__2iw_f,
    .marginsAndPaddings_px-xl-2__213KB {
        padding-right: 0.5rem !important;
    }

    .marginsAndPaddings_pb-xl-2__1dpb0,
    .marginsAndPaddings_py-xl-2__3aJBI {
        padding-bottom: 0.5rem !important;
    }

    .marginsAndPaddings_pl-xl-2__JmNDs,
    .marginsAndPaddings_px-xl-2__213KB {
        padding-left: 0.5rem !important;
    }

    .marginsAndPaddings_p-xl-3__3Tqxv {
        padding: 1rem !important;
    }

    .marginsAndPaddings_pt-xl-3__2yhNX,
    .marginsAndPaddings_py-xl-3__2Ltr7 {
        padding-top: 1rem !important;
    }

    .marginsAndPaddings_pr-xl-3__1JWLU,
    .marginsAndPaddings_px-xl-3__2sqUH {
        padding-right: 1rem !important;
    }

    .marginsAndPaddings_pb-xl-3__2g-gn,
    .marginsAndPaddings_py-xl-3__2Ltr7 {
        padding-bottom: 1rem !important;
    }

    .marginsAndPaddings_pl-xl-3__17jPj,
    .marginsAndPaddings_px-xl-3__2sqUH {
        padding-left: 1rem !important;
    }

    .marginsAndPaddings_p-xl-4__16qm- {
        padding: 1.5rem !important;
    }

    .marginsAndPaddings_pt-xl-4__3iGUd,
    .marginsAndPaddings_py-xl-4__2q33n {
        padding-top: 1.5rem !important;
    }

    .marginsAndPaddings_pr-xl-4__3A8uK,
    .marginsAndPaddings_px-xl-4__voPsn {
        padding-right: 1.5rem !important;
    }

    .marginsAndPaddings_pb-xl-4__2ScRL,
    .marginsAndPaddings_py-xl-4__2q33n {
        padding-bottom: 1.5rem !important;
    }

    .marginsAndPaddings_pl-xl-4__3CBov,
    .marginsAndPaddings_px-xl-4__voPsn {
        padding-left: 1.5rem !important;
    }

    .marginsAndPaddings_p-xl-5__tA-F0 {
        padding: 3rem !important;
    }

    .marginsAndPaddings_pt-xl-5__rYLca,
    .marginsAndPaddings_py-xl-5__1mbKF {
        padding-top: 3rem !important;
    }

    .marginsAndPaddings_pr-xl-5__1c1ov,
    .marginsAndPaddings_px-xl-5__3FL5G {
        padding-right: 3rem !important;
    }

    .marginsAndPaddings_pb-xl-5__Wo6S3,
    .marginsAndPaddings_py-xl-5__1mbKF {
        padding-bottom: 3rem !important;
    }

    .marginsAndPaddings_pl-xl-5__2GQ_H,
    .marginsAndPaddings_px-xl-5__3FL5G {
        padding-left: 3rem !important;
    }

    .marginsAndPaddings_m-xl-auto__62Wl_ {
        margin: auto !important;
    }

    .marginsAndPaddings_mt-xl-auto__YU20t,
    .marginsAndPaddings_my-xl-auto__vViut {
        margin-top: auto !important;
    }

    .marginsAndPaddings_mr-xl-auto__3hjzE,
    .marginsAndPaddings_mx-xl-auto__3HhaT {
        margin-right: auto !important;
    }

    .marginsAndPaddings_mb-xl-auto__1oYJU,
    .marginsAndPaddings_my-xl-auto__vViut {
        margin-bottom: auto !important;
    }

    .marginsAndPaddings_ml-xl-auto__1asSP,
    .marginsAndPaddings_mx-xl-auto__3HhaT {
        margin-left: auto !important;
    }
}

.Header_headerParticles__2o9ZF {
    color: white;
    text-align: center;
    font-size: 3rem;
    font-weight: 400;
}

.buttons_antiButton__2XSmc {
    border: none;
    outline: none;
    background: none;
}

.buttons_antiButton__2XSmc:focus {
    outline: none;
}

.buttons_redLink__3xUks {
    background: none !important;
    border: none;
    padding: 0 !important;

    /*optional*/
    /* font-family: arial, sans-serif; input has OS specific font-family */
    color: #C33;
    cursor: pointer;
}

.buttons_redLink__3xUks:hover {
    text-decoration: underline;
}

.buttons_alignLeftLink__163My {
    text-align: left;
    vertical-align: text-top;
}

.buttons_buttonToLeftLink__3ERbh {
}

.buttons_buttonToLink__1gFLb {
}

.buttons_btn__1a3sP {
    display: inline-block;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 2rem;
    margin: 2px;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
}

.buttons_btnPrimary__3To3n {
    background-image: -webkit-linear-gradient(top, var(--primary-color) 0%, var(--link-hover-color) 100%);
    background-image: linear-gradient(-180deg, var(--primary-color) 0%, var(--link-hover-color) 100%);
    color: white;
    border: 1px solid transparent;
}

.buttons_btnPrimary__3To3n:hover {
    background-image: none;
    background-color: #d6dade;
    color: var(--body-text);
    border: 1px solid var(--link-hover-color);
    border-bottom-color: var(--link-hover-color) !important;
}

.buttons_btnActionViolet__2Btxv {
    background-image: -webkit-linear-gradient(top, #ab80cd 0%, var(--action__color--violet) 100%);
    background-image: linear-gradient(-180deg, #ab80cd 0%, var(--action__color--violet) 100%);
    color: white;
}

.buttons_btnActionLightgray__CGfwU {
    background-color: #d6dade;
    color: var(--body-text);
    border: 1px solid var(--link-hover-color);
}

.buttons_btnActionLightgray__CGfwU:hover,
.buttons_btnActionLightgray__CGfwU:focus {
    background-image: -webkit-linear-gradient(top, var(--primary-color) 0%, var(--link-hover-color) 100%);
    background-image: linear-gradient(-180deg, var(--primary-color) 0%, var(--link-hover-color) 100%);
    color: white;
    border: 1px solid transparent;
}

.buttons_btnActionViolet__2Btxv:hover,
.buttons_btnActionViolet__2Btxv:focus {
    color: #f2f2f2;
    box-shadow: inset 0 -2px 0 rgba(22, 30, 37, 0.15)
}

.checkboxes_advancedCheckbox__3kU41 {
    display: inline;
    padding: 10px 0;
}

.checkboxes_advancedCheckbox__3kU41 input[type="checkbox"] {
    width: 50px;
}

.checkboxes_advancedCheckbox50px__2LwBr {
    width: 50px;
}

.misc_offsetTwentyRight__2pT9j {
    padding-left: 20px;
}

.misc_textCenter__2_7Hj {
    text-align: center;
}

.misc_textLeft__1nIAf {
    text-align: left;
}

@supports (display:grid) {
    .grid_grid__3LIhE {
        display: grid;
    }

    .grid_gridCenter__2Ue5u {
        align-items: center;
        justify-content: center;
    }

    .grid_gridNav__1pEbh {
        grid-template-columns: 1fr 2fr;
        grid-gap: 40px;
    }

    .grid_navButtons__21dko {
        grid-template-columns: 2fr 1fr;
        align-items: center;
        justify-content: flex-start;
        align-self: center;
        justify-self: center;
    }
}

@supports (display:-ms-grid) {
    .grid_grid__3LIhE {
        display: -ms-grid;
    }

    .grid_gridCenter__2Ue5u {
        -ms-flex-align: center;
        -ms-flex-pack: center;
    }

    .grid_gridNav__1pEbh {
        -ms-grid-columns: 1fr 2fr;
        grid-gap: 40px;
    }

    .grid_navButtons__21dko {
        -ms-grid-columns: 2fr 1fr;
        -ms-flex-align: center;
        -ms-flex-pack: flex-start;
        -ms-grid-row-align: center;
        -ms-grid-column-align: center;
    }
}

.NodeGraph_nodeGraphBox__3gb43 {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
}

.NodeGraph_nodeGraphCanvas__1G6SN {
    width: 100%;
    height: 100%;
    display: block;
}

.ScatterPlot_chartBox__23aBC {
    display: flex;
    flex-flow: column;
    height: 100%;
}

.ScatterPlot_chartTitle__1nxsk {
    flex: 0 1 auto;
    text-align: center;
}

.ScatterPlot_chart__6MM17 {
    flex: 1 1 auto;
    width: 100%;
}

.ArticleDisplay_breakOnSpaces__1Gfcn {
    white-space: pre-line;
}

.ArticleDisplay_yourHighlightClass__g_1Yx {

}

.TwoAxisChart_box__2R8v1 {
    display: grid;
    grid-template-rows: auto auto auto -webkit-min-content;
    grid-template-rows: auto auto auto min-content;
    grid-template-columns: -webkit-min-content auto auto auto;
    grid-template-columns: min-content auto auto auto;
}

.TwoAxisChart_svg__19PO4 {
    grid-row: 1 / 4;
    grid-column: 2 / 5;
    width: 100%;
    height: 100%;
}

.TwoAxisChart_selectCSS__3H8Jd {
    font-size: 15px;
}

.TwoAxisChart_selectYContainer__1010Z {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    width: 20px;
    height: 200px;
}

.TwoAxisChart_selectY__3_iuE {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
}

.TwoAxisChart_selectX__1MIq7 {
    grid-row: 4 / 5;
    grid-column: 3 / 4;
}

.TwoAxisChart_legend__YET6b {
    grid-row: 5 / 6;
    grid-column: 1 / 5
}

.TwoAxisChart_moveSecondDown__26y9b > g:nth-child(2n + 1) > text {
    -webkit-transform: translateY(15px);
            transform: translateY(15px)
}

.ContextTable_contextTable__89nNI {
    width: 100%;
    height: 100%;
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
    table-layout: fixed;
}

.ContextTable_tableBody__1VzI3 {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
}

.StatisticsTable_cleanTable__29tsL {
    border-collapse: collapse;
    width: 100%;
    height: 100%;
    display: block;
    overflow-x: auto;
    overflow-y: auto;
}

.StatisticsTable_cleanTable__29tsL > thead > tr > th, .StatisticsTable_cleanTable__29tsL > tbody > tr > td, .StatisticsTable_cleanTable__29tsL > tbody > tr > th {
    border: 1px solid black
}

.StatisticsTable_StatisticsTable-antiButton__yE42d {
    border: none;
    outline: none;
    background: none;
}

.StatisticsTable_StatisticsTable-antiButton__yE42d:focus {
    outline: none;
}

.StatisticsTable_StatisticsTable-redLink__XIf1H {
    background:none!important;
    border:none;
    padding:0!important;

    /*optional*/
    font-family:arial,sans-serif; /*input has OS specific font-family*/
    color: #C33;
    cursor:pointer;

    text-align: left;
    vertical-align: text-top;
}

.StatisticsTable_StatisticsTable-redLink__XIf1H:hover{
    text-decoration:underline;
}
/* slightly transparent fallback */
.StatsButton_backdropBlur__2T3Kz {
    background-color: rgba(127, 127, 127, .9);
}

/* if backdrop support: very transparent and blurred */
@supports ((-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))) {
    .StatsButton_backdropBlur__2T3Kz {
        background-color: rgba(255, 255, 255, .5);
        -webkit-backdrop-filter: blur(2em);
        backdrop-filter: blur(2em);
    }
}

.StatsButton_ChartTable__2k1Av {
    overflow: hidden;
    margin: 0 10px;
}
.FullScreenButton_button__2cSSz {
    display: block;
    border-radius: 4px;
    border: solid #c0c0c0 1px;
    background-color: #cccccc;
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 2px;
    line-height: 50%;
}

.FullScreenButton_button__2cSSz:hover {
    background-color: #bdbdbd;
}

.FullScreenButton_button__2cSSz:active {
    background-color: #a4a4a4;
}

.DisplayOptions_ul__3GycL {
    list-style: none;
    width: 100%;
}

.DisplayOptions_ul__3GycL li {
    display: inline;
}


.DisplayTable_table__28lrx, .DisplayTable_table__28lrx > tbody > tr > td {
    table-layout: fixed;
    width: 100%;
    border: solid 2px black;
    border-collapse: collapse;
    vertical-align: top;
}

.DisplayTable_grid__2Iqjn {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
    -webkit-transition: all 1s;
    transition: all 1s;
}

.DisplayTable_nodeGraph__3ikW5 {
    position: relative;
    -ms-grid-row: 1;
    -ms-grid-column: 1;
}

.DisplayTable_contextTable__P6SuB {
    position: relative;
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    background-color: #f7f7f7;
}

.DisplayTable_barGraph__2mdV5 {
    position: relative;
    -ms-grid-row: 2;
    -ms-grid-column: 2;
}

.DisplayTable_authorAudit__34NyQ {
    position: relative;
    overflow: auto;
    -ms-grid-row: 2;
    -ms-grid-column: 1;
}

:root {
  --primary-color: #49B84F;
  --accent-color: #f3f5f7;
  --body-text: #212121;
  --body-text-light: #333;
  --link-hover-color: rgb(29, 146, 35);
  --action__color--violet: #8749b8;
  --font-family-head: "Lato", sans-serif;
  --font-family-body: "Source Sans Pro", sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: "Source Sans Pro", sans-serif;
  font-family: var(--font-family-body);
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

@-ms-viewport {
  width: device-width;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif;
  font-family: var(--font-family-body);
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; */
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212121;
  color: var(--body-text);
  text-align: left;
  background-color: white;
  overflow-x: hidden;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #49B84F;
  color: var(--primary-color);
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: rgb(29, 146, 35);
  color: var(--link-hover-color);
  text-decoration: none;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: #212121;
  color: var(--body-text);
  font-weight: 400;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

/* Button */

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

.main_sweet-alert__2ByU7 button.main_confirm__1loVY {
  background-color: rgb(73, 184, 79) !important;
  color: white;
}

.main_sweet-alert__2ByU7 button.main_confirm__1loVY:hover {
  background-color: rgb(110, 198, 114) !important;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.main_h1__1tcFx,
.main_h2__2EHQj,
.main_h3__1VXG1,
.main_h4__2dMD3,
.main_h5__1HbVD,
.main_h6__2QQMO {
  margin-bottom: 0.5rem;
  font-family: "Lato", sans-serif;
  font-family: var(--font-family-head);
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

h1,
.main_h1__1tcFx {
  font-size: 2.5rem;
}

h2,
.main_h2__2EHQj {
  font-size: 2rem;
}

h3,
.main_h3__1VXG1 {
  font-size: 1.75rem;
}

h4,
.main_h4__2dMD3 {
  font-size: 1.5rem;
}

h5,
.main_h5__1HbVD {
  font-size: 1.25rem;
}

h6,
.main_h6__2QQMO {
  font-size: 1rem;
}

.main_lead__2xxap {
  font-size: 1.25rem;
  font-weight: 300;
}

.main_display-1__Eb8MC {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.main_display-2__3AxRL {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.main_display-3__39-11 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.main_display-4__g3PTR {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.main_small__1wijK {
  font-size: 80%;
  font-weight: 400;
}

/* utilities */

.main_w-25__f0Xzi {
  width: 25% !important;
}

.main_w-50__1Yd9v {
  width: 50% !important;
}

.main_w-75__1FRzJ {
  width: 75% !important;
}

.main_w-100__1iXca {
  width: 100% !important;
}

.main_w-auto__2ZtJF {
  width: auto !important;
}

.main_h-25__2UQTQ {
  height: 25% !important;
}

.main_h-50__3Bh16 {
  height: 50% !important;
}

.main_h-75__1VJbG {
  height: 75% !important;
}

.main_h-100__1SkrY {
  height: 100% !important;
}

.main_h-auto__3o_QX {
  height: auto !important;
}

.main_displayBlock__x4xik {
  display: block !important;
}

.main_display-hidden__bzv4Q {
  display: none !important;
}

.main_b-100__2lQAS {
  font-weight: 100;
}

.main_b-200__1_Zej {
  font-weight: 200;
}

.main_b-300__3ox-B {
  font-weight: 300;
}

.main_b-400__wUUsA {
  font-weight: 400;
}

.main_b-500__3RegF {
  font-weight: 500;
}

.main_b-600__3BnsO {
  font-weight: 600;
}

.main_b-700__1PSFV {
  font-weight: 700;
}

/* Backgrounds */

.main_bg-main__1pv-M {
  background-color: white;
}

.main_bg-accent__f6hh2 {
  background-color: #f3f5f7;
  background-color: var(--accent-color);
}

/* navbar */

.main_navbar__3bgdo {
  width: 100%;
  margin: 0;
  background-color: white;
  height: 65px;
  overflow: hidden;
  box-shadow: 0 6px 6px -6px #212121;
}

.main_navbarBrand__3ZohA {
  float: left;
  display: block;
}

.main_navbarBrand__3ZohA img {
  width: 33%;
  height: auto;
}


.main_container__1NbQm {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .main_container__1NbQm {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .main_container__1NbQm {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .main_container__1NbQm {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .main_container__1NbQm {
        max-width: 1140px;
    }
}

/* footer */

footer {
  min-height: 70px;
  padding: 20px 0;
  background-color: #333;
  background-color: var(--body-text-light);
  color: #f1f1f1;
  bottom: 0;
}

.TopicChartScatterPlot_chartBox__eqQha {
    display: flex;
    flex-flow: column;
    height: 100%;
}

.TopicChartScatterPlot_chartTitle__2rnxl {
    flex: 0 1 auto;
    text-align: center;
}

.TopicChartScatterPlot_chart__3xLw0 {
    flex: 1 1 auto;
    width: 100%;
}

@supports (display:-ms-grid) {
    .App_App__33jfa {
        width: 100vw;
        height: auto;
        display: -ms-grid;
        -ms-grid-rows: 30px 1fr;
    }
}

@supports (display:grid) {
    .App_App__33jfa {
        width: 100vw;
        height: auto;
        display: grid;
        grid-template-rows: 30px auto;
    }
}

@supports (display:-ms-grid) {
    .App_content__3xonP {
        display: block;
        overflow: hidden;
        -ms-grid-row-span: 2;
    }
}

@supports (display:grid) {
    .App_content__3xonP {
        display: block;
        overflow: hidden;
        grid-area: 1 / 1 / 3 / 1;
    }
}

.App_search__2MsoH {
    overflow: auto;
}

@supports (display:-ms-grid) {
    .App_bottomBox__3OG1b {
        -ms-grid-row: 2;
    }
}

@supports (display:grid) {
    .App_bottomBox__3OG1b {
        grid-area: 1 / 1 / 3 / 1;
        padding-top: 3px;
    }

}

html, body {
    margin: 0;
    padding: 0;
}

@-webkit-keyframes App_App-logo-spin__3XqR1 {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App_App-logo-spin__3XqR1 {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.App_menu-button__wU_1l {
    /* width: 50%; */
    margin-right:5px;
}

.App_menu-button-right__3Zrw5 {
    float: right;
}

