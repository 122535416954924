.nodeGraphBox {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
}

.nodeGraphCanvas {
    width: 100%;
    height: 100%;
    display: block;
}
