.button {
    display: block;
    border-radius: 4px;
    border: solid #c0c0c0 1px;
    background-color: #cccccc;
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 2px;
    line-height: 50%;
}

.button:hover {
    background-color: #bdbdbd;
}

.button:active {
    background-color: #a4a4a4;
}
