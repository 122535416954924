.chartBox {
    display: flex;
    flex-flow: column;
    height: 100%;
}

.chartTitle {
    flex: 0 1 auto;
    text-align: center;
}

.chart {
    flex: 1 1 auto;
    width: 100%;
}
