.advancedCheckbox {
    display: inline;
    padding: 10px 0;
}

.advancedCheckbox input[type="checkbox"] {
    width: 50px;
}

.advancedCheckbox50px {
    composes: advancedCheckbox;
    width: 50px;
}
