
.table, .table > tbody > tr > td {
    table-layout: fixed;
    width: 100%;
    border: solid 2px black;
    border-collapse: collapse;
    vertical-align: top;
}

.grid {
    width: 100%;
    height: 100%;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 50% 50%;
    grid-template-columns: 50% 50%;
    -ms-grid-rows: 50% 50%;
    grid-template-rows: 50% 50%;
    transition: all 1s;
}

.nodeGraph {
    position: relative;
    -ms-grid-row: 1;
    -ms-grid-column: 1;
}

.contextTable {
    position: relative;
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    background-color: #f7f7f7;
}

.barGraph {
    position: relative;
    -ms-grid-row: 2;
    -ms-grid-column: 2;
}

.authorAudit {
    position: relative;
    overflow: auto;
    -ms-grid-row: 2;
    -ms-grid-column: 1;
}
